<template>
  <div>
    <b-row v-if="isLoadingData">
      <b-col class="px-4 px-sm-5 py-4 vh-100">
        <img src="@/assets/icons/loading.svg" class="loading" alt="loading" />
      </b-col>
    </b-row>
    <HeaderPanel
      v-else
      title="News/Promotion News"
      :filter="filter"
      :hasDropdown="false"
      :hasFilter="false"
      :hasSearch="false"
      routerPath="/promotionnews/details/0"
    />
    <div class="bg-white border-red p-2 p-lg-3 mt-3">
      <b-row>
        <b-col>
          <b-table
            responsive
            striped
            hover
            :fields="fields"
            :items="items"
            :busy="isBusy"
            show-empty
            empty-text="No matching records found"
          >
            <template v-slot:cell(name)="data">
              <router-link :to="'/promotionnews/details/' + data.item.id">
                <u>
                  {{
                    data.item.name
                      ? data.item.name
                      : data.item.news_language
                      ? data.item.news_language[0].name
                      : "-"
                  }}
                </u>
              </router-link>
            </template>
            <template v-slot:cell(image_url)="data">
              <div
                class="d-flex justify-content-center"
                v-if="data.item.image_url"
              >
                <b-img
                  @error="handleImageSrc"
                  class="box-image1-1"
                  :src="data.item.image_url"
                  fluid
                ></b-img>
              </div>
              <div v-else class="d-flex justify-content-center">
                <b-img class="box-image1-1" :src="default_image" fluid></b-img>
              </div>
            </template>
            <template v-slot:cell(created_time)="data">
              <div>{{ data.item.created_time | moment($formatDateNew) }}</div>
              <div class="time-color">
                {{ data.item.created_time | moment("HH:mm:ss") }}
              </div>
            </template>
            <template v-slot:cell(status)="data">
              <span class="text-success" v-if="data.item.status === 1">
                Active
              </span>
              <span class="text-danger" v-if="data.item.status === 0">
                Inactive
              </span>
            </template>
            <template v-slot:cell(action)="data">
              <div class="d-flex justify-content-center">
                <router-link :to="'/promotionnews/details/' + data.item.id">
                  <b-button variant="link" class="text-warning px-1 py-0">
                    <font-awesome-icon icon="pencil-alt" title="Edit" />
                  </b-button>
                </router-link>
                <b-button
                  variant="link"
                  class="text-danger px-1 py-0"
                  @click="AlertDelete(data.item.id)"
                >
                  <font-awesome-icon
                    icon="trash-alt"
                    title="Delete"
                    class="main-color"
                  />
                </b-button>
              </div>
            </template>
            <template v-slot:table-busy>
              <div class="text-center text-black my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong class="ml-2">Loading...</strong>
              </div>
            </template>
          </b-table>
        </b-col>
      </b-row>

      <Pagination
        :pageOptions="pageOptions"
        :filter="filter"
        :rows="rows"
        @pagination="pagination"
        @handleChangeTake="hanndleChangePerpage"
      />
    </div>
  </div>
</template>

<script>
import HeaderPanel from "@/components/HeaderPanel";
import Pagination from "@/components/Pagination";

export default {
  name: "PromotionNewsIndex",
  components: { HeaderPanel, Pagination },
  data() {
    return {
      fields: [
        {
          key: "image_url",
          label: "Image",
          thStyle: { width: "15%" },
          tdClass: "p-3",
        },
        {
          key: "name",
          label: "Name",
          thStyle: { width: "25%" },
        },
        {
          key: "news_type",
          label: "Type",
          tdClass: "w-200",
        },
        {
          key: "created_time",
          label: "Create Date",
        },
        {
          key: "status",
          label: "Status",
        },
        {
          key: "action",
          label: "Action",
        },
      ],
      items: [],
      isBusy: false,
      checkAllPromotion: false,
      selectAllPromotion: false,
      checkAllStatus: false,
      selectAllStatus: false,
      rows: 0,
      filter: {
        search: "",
        promotion: [],
        status: [],
        page: 1,
        take: 10,
        SortByOrderOrId: 0,
      },
      pageOptions: [
        { value: 10, text: "10 / page" },
        { value: 30, text: "30 / page" },
        { value: 50, text: "50 / page" },
        { value: 100, text: "100 / page" },
      ],
      selected: 0,
      isLoadingData: false,
    };
  },
  created: async function () {
    await this.getList();
  },
  methods: {
    getList: async function () {
      await this.$store.dispatch("ActionGetNews", this.filter);
      var data = await this.$store.state.moduleConnectAPI.stateNews;

      this.isBusy = true;
      this.filter.SortByOrderOrId = this.selected;
      if (data.result == 1) {
        this.items = data.detail.detail;
        this.rows = data.detail.total_count;
        this.isBusy = false;
      }
    },
    handleSearch(e) {
      if (e.keyCode === 13) {
        this.filter.page = 1;
        this.getList();
      }
    },
    pagination(page) {
      this.filter.page = page;
      this.getList();
    },
    getDataByStatus() {
      this.$refs.filterSidebar.hide();
      this.getList();
    },
    hanndleChangePerpage(value) {
      this.filter.page = 1;
      this.filter.take = value;
      this.getList();
    },
    checkPromotionLength() {
      if (this.filter.promotion.length == 2) {
        this.selectAllPromotion = true;
      } else {
        this.selectAllPromotion = false;
      }
    },
    checkAllSelectPromotion() {
      if (this.selectAllPromotion) {
        this.filter.promotion = [];
      } else {
        this.filter.promotion = [1, 0];
      }
    },
    checkStatusLength() {
      if (this.filter.status.length == 2) {
        this.selectAllStatus = true;
      } else {
        this.selectAllStatus = false;
      }
    },
    checkAllSelect() {
      if (this.selectAllStatus) {
        this.filter.status = [];
      } else {
        this.filter.status = [1, 0];
      }
    },
    AlertDelete(id) {
      this.confirmAlert().then((val) => {
        if (val.isConfirmed) {
          this.deleteData(id);
        }
      });
    },
    async deleteData(id) {
      this.isLoadingData = true;
      await this.$store.dispatch("ActionDeleteNews", id);
      var data = await this.$store.state.moduleConnectAPI.stateDeleteNews;
      if (data.result == 1) {
        this.isLoadingData = false;
        this.successAlert().then(() => {
          this.getList();
        });
      }
    },
  },
};
</script>
